<template>
  <main-template>
    <v-row>
      <v-col>
        <span class="text-h4">Movimentação</span>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-sheet color="white" elevation="1" rounded>
          <v-lazy>
            <div class="text-start py-2 px-4 text-button">Pesquisa avançada</div>
          </v-lazy>
          <v-divider class="theme--light"></v-divider>
          <div class="py-2 px-4">
            <v-row>
              <v-col cols="12" sm="4" xs="12">
                <v-menu
                  ref="menu1"
                  v-model="menu1"
                  :close-on-content-click="false"
                  offset-y
                  max-width="290px"
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      readonly
                      v-model="dateFormatted"
                      v-bind="attrs"
                      solo
                      @change="date = parseDate(dateFormatted)"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker v-model="date" no-title @input="menu1 = false"></v-date-picker>
                </v-menu>
              </v-col>

              <v-col cols="12" sm="4" xs="12">
                <v-autocomplete
                  v-model="caixaSelecionado"
                  :items="caixas"
                  solo
                  label="Selecione um caixa"
                  item-text="descricao"
                  item-value="id"
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" sm="4" xs="12">
                <v-btn
                  color="primary lighten-1"
                  class="mt-1"
                  solo
                  large
                  type="submit"
                  @click="() => pesquisar()"
                >
                  Pesquisar
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </v-sheet>
      </v-col>

      <v-col>
        <template>
          <v-card>
            <v-row>
              <v-col cols="12" sm="8" xs="12">
                <v-list-item-content class="px-5 my-3" color="primary" v-if="caixaAtual">
                  <v-list-item-title class="mt-3 caption">
                    <span class="font-weight-bold">Situação: {{ caixaAtual.descricao }}</span>
                    <div></div>
                    <span class="font-weight-bold">
                      <v-chip
                        class="mt-2"
                        v-if="situacoes.situacao === 1"
                        small
                        label
                        color="green lighten-4"
                      >
                        Aberto
                      </v-chip>

                      <v-chip
                        class="mt-2"
                        v-if="situacoes.situacao === 0"
                        small
                        label
                        color="red lighten-4"
                      >
                        Fechado
                      </v-chip>

                      <v-chip
                        class="mt-2"
                        v-if="Object.keys(situacoes).length === 0"
                        small
                        label
                        color="orange lighten-4"
                      >
                        Sem Movimentação
                      </v-chip>
                    </span>
                  </v-list-item-title>

                  <v-list-item-subtitle class="mt-3 caption">
                    <span class="font-weight-bold" v-if="Object.keys(situacoes).length !== 0">
                      Criado por: {{ caixaAtual.user ? caixaAtual.user.email : "--" }} | Criação:
                      {{ caixaAtual.created_at | formatarData }}
                    </span>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-col>

              <v-divider vertical></v-divider>

              <v-col rols="4" class="d-flex flex-column align-center justify-center">
                <div>Valor Total:</div>
                <div class="display-2">
                  {{ totalDoCaixa | currency }}
                </div>
              </v-col>
            </v-row>
          </v-card>
        </template>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-row>
          <v-col>
            <p>Lista de Sangria do caixa</p>
          </v-col>
        </v-row>
        <v-simple-table>
          <template>
            <thead>
              <tr>
                <th>#</th>
                <th>Data</th>
                <th>Usuário</th>
                <th class="text-center">Situação</th>
                <th class="text-center">Valor</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in sangriasCaixa" :key="item.id">
                <td>{{ item ? item.id : "- - -" }}</td>
                <td>{{ item.created_at | formatarData }}</td>
                <td>{{ item ? item.user.name : "- - -" }}</td>
                <td class="text-center">
                  <v-chip
                    :color="parseInt(item.situacao, 10) === 1 ? 'green lighten-4' : 'red lighten-4'"
                  >
                    {{ parseInt(item.situacao, 10) === 1 ? "Aberto" : "Fechado" }}
                  </v-chip>
                </td>
                <td class="text-right">{{ item.valor | currency }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="4" class="text-right font-weight-bold">Total</td>
                <td class="text-right font-weight-bold">
                  <span class="red--text">
                    - {{ somaTotalSangria(sangriasCaixa) | currency }}
                  </span>
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-row>
          <v-col>
            <p>Lista de Movimentação do caixa</p>
          </v-col>
        </v-row>
        <v-simple-table>
          <template>
            <thead>
              <tr>
                <th>#</th>
                <th>Data</th>
                <th>Usuário</th>
                <th class="text-center">Situação</th>
                <th class="text-center">Valor</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in situacoesCaixa" :key="item.id">
                <td>{{ item ? item.id : "- - -" }}</td>
                <td>{{ item.created_at | formatarData }}</td>
                <td>{{ item ? item.user.name : "- - -" }}</td>
                <td class="text-center">
                  <v-chip
                    :color="parseInt(item.situacao, 10) === 1 ? 'green lighten-4' : 'red lighten-4'"
                  >
                    {{ parseInt(item.situacao, 10) === 1 ? "Aberto" : "Fechado" }}
                  </v-chip>
                </td>
                <td class="text-right">{{ item.valor | currency }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="4" class="text-right font-weight-bold">Total</td>
                <td class="text-right font-weight-bold">
                  <span class="blue--text"> + {{ somaTotal(situacoesCaixa) | currency }} </span>
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-row>
          <v-col>
            <p>Lista das Mensalidades</p>
          </v-col>
        </v-row>
        <v-simple-table>
          <template>
            <thead>
              <tr>
                <th class="text-left">#</th>
                <th class="text-left">Data</th>
                <th class="text-left">Turma</th>
                <th class="text-left">Parcela</th>
                <th class="text-left">Aluno</th>
                <th class="text-left">Tipo de pagamento</th>
                <th class="text-left">Situação</th>
                <th class="text-left">Valor</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in mensalidades" :key="item.id">
                <td>{{ item.id }}</td>
                <td>{{ item.created_at | formatarData }}</td>
                <td>{{ item.parcela ? item.parcela.matricula.turma.descricao : "- - -" }}</td>
                <td class="text-center">{{ item.parcela ? item.parcela.numero : "- - -" }}</td>
                <td>{{ item.parcela ? item.parcela.matricula.aluno.nomecompleto : "- - -" }}</td>
                <td class="text-center">{{ item.forma_de_pagamento.descricao }}</td>
                <td class="text-center">
                  <v-chip color="red lighten-4" v-if="item.parent_id && item.estorno">
                    {{ "Estorno" }}
                  </v-chip>
                  <v-chip color="green lighten-4" v-if="!item.estorno">
                    {{ "Pago" }}
                  </v-chip>
                  <v-chip color="orange lighten-4" v-if="!item.parent_id && item.estorno">
                    {{ "Cancelado" }}
                  </v-chip>
                </td>
                <td class="text-right">{{ item.valor | currency }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="7" class="text-right font-weight-bold">Total</td>
                <td class="text-right font-weight-bold">
                  <span class="blue--text"> + {{ somaTotal(mensalidades) | currency }} </span>
                </td>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <v-dialog v-model="showDialog" persistent max-width="400">
      <v-card>
        <v-card-title class="text-h5"> Caixa fechado </v-card-title>
        <v-card-text>Por favor abra o caixa antes de continuar.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="[(showDialog = false), abrirCaixa()]">
            Abrir Caixa
          </v-btn>
          <v-btn color="green darken-1" text @click="[(showDialog = false)]"> Continuar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="modalFecharCaixa" persistent max-width="420">
      <v-card>
        <v-card-title class="text-h5"> Você deseja fechar o caixa? </v-card-title>
        <v-card-text>Valor caixa atual: {{ totalDoCaixa | currency }}</v-card-text>
        <v-card-text>O caixa não pode ser mais aberto após essa ação</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="[(modalFecharCaixa = false), fecharCaixa()]">
            Continuar
          </v-btn>
          <v-btn color="green darken-1" text @click="modalFecharCaixa = false"> Cancelar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("Auth", ["user"]),

    totalDoCaixa() {
      return (
        parseFloat(this.somaTotal(this.mensalidades)) +
        parseFloat(this.somaTotal(this.situacoesCaixa)) -
        parseFloat(this.somaTotalSangria(this.sangriasCaixa))
      );
    },
  },

  watch: {
    date() {
      this.dateFormatted = this.formatDate(this.date);
    },
  },

  data() {
    return {
      showDialog: false,
      modalFecharCaixa: false,
      sangriasCaixa: [],
      situacoesCaixa: [],
      mensalidades: [],
      caixaSelecionado: null,
      caixaAtual: null,
      caixas: [],
      situacoes: null,
      date: null,
      dateFormatted: null,
      menu1: false,
    };
  },

  mounted() {
    if (!this.user.caixa) {
      this.showDialog = true;
    }
    this.loadData();
  },

  methods: {
    async loadData() {
      this.getCaixasUser();
      this.date = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
      this.caixaSelecionado = this.user?.caixa?.id;
      this.pesquisar();
      this.getSangriasCaixa(this.user?.caixa?.id, this.date);
    },

    async fecharCaixa() {
      try {
        await this.$services.caixasService.fecharCaixaId(this.caixaSelecionado, this.totalDoCaixa);
        this.$toast.success("Caixa fechado com sucesso!!!");
      } catch (error) {
        this.$handleError(error);
      }
    },

    async pesquisar() {
      const user_id = this.user.id;
      this.getCaixas(user_id, this.caixaSelecionado, this.date);
      this.getMensalidades(this.caixaSelecionado, this.date);
      this.getSangriasCaixa(this.caixaSelecionado, this.date);
    },

    async getCaixas(user_id, caixa_id, date) {
      this.$loaderService.open("Carregando Historico");
      const response = await this.$services.movimentacaoService.getCaixas(user_id, caixa_id, date);
      this.caixaAtual = { ...response.caixa };
      this.situacoes = { ...response.situacao_caixas };
      this.$loaderService.close();
    },

    async getCaixasUser() {
      const response = await this.$services.movimentacaoService.getCaixasUser(this.user.id);
      this.caixas = [...response];
    },

    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },

    async getMensalidades(caixa_id, date) {
      const response = await this.$services.movimentacaoService.getMensalidades(caixa_id, date);
      // console.log(response);
      this.mensalidades = [...response.mensalidades];
      this.situacoesCaixa = response.situacoes;
    },

    async getSangriasCaixa(caixa_id, date) {
      const response = await this.$services.sangriaService.getSangriasDataAtual(caixa_id, date);
      this.sangriasCaixa = response;
    },

    somaTotal(valores) {
      return valores.reduce((ac, vc) => {
        return parseFloat(parseFloat(ac) + parseFloat(vc.valor));
      }, 0);
    },

    somaTotalSangria(valores) {
      return valores.reduce((ac, vc) => {
        if (!vc.valor) {
          return;
        }

        return parseFloat(parseFloat(ac) + parseFloat(vc.valor));
        // parseFloat(parseFloat(ac) + parseFloat(vc.valor));
      }, 0);
    },

    async verificarCaixaAberto() {
      if (!this.user.caixa) {
        this.showDialog = true;
      }
    },

    abrirCaixa() {
      this.$router.push({ name: "caixas" });
    },
  },
};
</script>
