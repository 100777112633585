<template>
  <v-row justify="center">
    <v-dialog v-model="dialog" persistent max-width="800px">
      <v-card>
        <v-card-title class="primary">
          <span style="color: #fff" class="text-h5">Exportar Aula</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <ValidationObserver v-slot="{ handleSubmit }">
              <form @submit.prevent="handleSubmit(enableSubmit)" :disabled="false">
                <v-row>
                  <v-col class="pt-4 pb-0" cols="12">
                    <e-label class="font-weight-medium">Turma</e-label>
                    <v-select
                      v-model="turmaSelecionada"
                      :items="turmasDoInstrutor"
                      item-text="descricao"
                      item-value="id"
                      return-object
                      label="Selecione a turma"
                    ></v-select>
                  </v-col>
                  <v-col class="pt-4 pb-0" cols="12">
                    <e-label class="font-weight-medium">Tipo de Aula</e-label>
                    <p class="font-weight-regular">
                      {{ gestaoDeAula.tipo_de_aula }}
                    </p>
                  </v-col>
                  <v-col class="pt-0 pb-0" :cols="12" :sm="4">
                    <ValidationProvider name="Data" rules="required" v-slot="{ errors }">
                      <e-label class="font-weight-medium">Data</e-label>
                      <e-date-picker v-model="dataAulaDuplicada" :error-messages="errors" />
                    </ValidationProvider>
                  </v-col>
                  <v-col
                    class="pt-0 pb-0"
                    :cols="12"
                    :sm="4"
                    v-if="gestaoDeAula.tipo_de_aula !== 'Aula Remota'"
                  >
                    <ValidationProvider
                      name="Horário inicial"
                      rules="required|timeValidator"
                      v-slot="{ errors }"
                    >
                      <e-label class="font-weight-medium">Horário inicial</e-label>
                      <e-time-picker
                        v-model="gestaoDeAula.horario_inicial"
                        :error-messages="errors"
                      />
                    </ValidationProvider>
                  </v-col>
                  <v-col
                    class="pt-0 pb-0"
                    :cols="12"
                    :sm="4"
                    v-if="gestaoDeAula.tipo_de_aula !== 'Aula Remota'"
                  >
                    <ValidationProvider
                      name="Horário final"
                      rules="required|timeValidator"
                      v-slot="{ errors }"
                    >
                      <e-label class="font-weight-medium">Horário final</e-label>
                      <e-time-picker
                        v-model="gestaoDeAula.horario_final"
                        :error-messages="errors"
                      />
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row v-if="turmaSelecionada.curso_id === 3">
                  <v-col cols="12">
                    <e-label class="font-weight-medium">Percurso Metodológico e Recursos</e-label>
                    <p class="font-weight-regular">
                      {{ gestaoDeAula.percurso }}
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <e-label class="font-weight-medium">Procedimentos Avaliativos</e-label>
                    <p class="font-weight-regular">
                      {{ gestaoDeAula.procedimentos }}
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <e-label class="font-weight-medium">Observações</e-label>
                    <p class="font-weight-regular">
                      {{ gestaoDeAula.observacoes }}
                    </p>
                  </v-col>
                </v-row>
                <v-col cols="12">
                  <v-alert v-if="!turmaSelecionada || !turmaSelecionada.id" type="warning"
                    >Por favor, selecione uma turma antes de exportar a aula.</v-alert
                  >
                </v-col>
                <v-row v-if="turmaSelecionada.curso_id != 3">
                  <v-col cols="12">
                    <e-label class="font-weight-medium">Conteúdo</e-label>
                    <p class="font-weight-regular">
                      {{ turmaSelecionada.conteudo }}
                    </p>
                  </v-col>
                  <v-col cols="12">
                    <e-label class="font-weight-medium">Metodologia</e-label>
                    <p class="font-weight-regular">
                      {{ gestaoDeAula.metodologia }}
                    </p>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    style="color: #fff"
                    class="red darken-1"
                    @click="$emit('dialogChange', false)"
                  >
                    Fechar
                  </v-btn>
                  <v-btn style="color: #fff" class="green darken-1" type="submit">Salvar</v-btn>
                </v-card-actions>
              </form>
            </ValidationObserver>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <e-modal-confirm
      :confirmDialog="confirmDialog"
      :objectModal="objectModal"
      @changeModalConfirm="enableSubmit"
      @changeActionResponse="exportarAula"
    ></e-modal-confirm>
  </v-row>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    submittingForm: {
      type: Boolean,
      default: false,
    },
    form: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      gestaoDeAula: {},
      confirmDialog: false,
      dataAulaDuplicada: null,
      turmas: [],
      turmasDoInstrutor: [],
      instrutorDisciplinaTurma_id: null,
      turmaSelecionadaId: null,
      turmaSelecionada: {
        turma: {
          id: null,
          franquia: {
            descricao: "",
            fantasia: "",
          },
        },
      },
      objectModal: {
        title: "Exportar aula",
        message: "Tem certeza que deseja exportar esta aula?",
      },
    };
  },
  watch: {
    form: {
      immediate: true,
      handler(val) {
        this.gestaoDeAula = val;
      },
    },
    dialog: {
      async handler(val) {
        if (val) {
          this.$loaderService.open("Carregando gestões de aula");
          try {
            const gestoes = await this.$services.gestoesDeAulasService.listarGestoesProfessor(
              this.gestaoDeAula
            );
            if (Array.isArray(gestoes.turmas)) {
              gestoes.turmas.forEach((turma) => {});
              this.gestoes = gestoes;
              this.atualizarTurmasDoInstrutor();
            } else {
              this.gestoes = {};
              this.turmasDoInstrutor = [];
            }
          } catch (error) {
            this.$handleError(error);
          } finally {
            this.$loaderService.close();
          }
        }
      },
    },
  },
  computed: {
    // turmasDoInstrutor() {
    //   return this.gestoes.turmas.filter((turma) => {
    //     return turma.instrutor_id === this.instrutorId;
    //   });
    // },
  },
  methods: {
    enableSubmit() {
      this.confirmDialog = !this.confirmDialog;
      this.gestaoDeAula.data = this.dataAulaDuplicada;
    },
    async exportarAula() {
      this.$loaderService.open("Exportando a Aula");
      try {
        if (this.turmaSelecionada) {
          const instrutorDisciplinaTurma_id = null;

          await this.$services.aulasService.criarAula({
            ...this.gestaoDeAula,
            turma_id: this.turmaSelecionada.id,
            instrutorDisciplinaTurma_id,
          });

          this.confirmDialog = !this.confirmDialog;
          this.$emit("dialogChange", false);
          this.$toast.success("Aula exportada com sucesso!");
        } else {
          this.$toast.danger("Por favor, selecione uma turma antes de exportar a aula.");
        }
      } catch (error) {
        this.$handleError(error);
      } finally {
        this.$loaderService.close();
      }
    },

    atualizarTurmasDoInstrutor() {
      if (Array.isArray(this.gestoes.turmas)) {
        this.turmasDoInstrutor = this.gestoes.turmas.filter((turma) => {
          return turma.instrutor_id === this.instrutorId;
        });
      } else {
        this.turmasDoInstrutor = [];
      }
    },
  },
};
</script>
