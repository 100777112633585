import Axios from "@/plugins/Axios";
import Matricula from "@/Models/Matricula";
import Parcela from "@/Models/Parcela";

class MatriculasService {
  async syncAll(pageNumber, query) {
    const response = await Axios().get(`matriculas?page=${pageNumber}&query=${query}`);
    return response.data;
  }

  async all() {
    const response = await Axios().get("matriculas/all");
    const data = response.data.map((matricula) => new Matricula(matricula));
    return data;
  }

  async getMatricula($matricula_id) {
    const response = await Axios()
      .get(`matriculas/carne/${$matricula_id}/boletos`, { responseType: "arraybuffer" })
      .then((response) => {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `carne_${$matricula_id}.pdf`;
        link.click();
      });
    return response;
  }

  async pegarMatricula(matricula_id) {
    const response = await Axios().get(`matriculas/pegar-matricula/${matricula_id}`);
    return response.data;
    // store.commit("Matriculas/atualizarMatricula", matriculaOcultado);
  }

  async matricular(form) {
    form.matriculas = form.matriculas.map((matricula) => ({
      aluno_id: matricula.aluno_id,
      turma_id: matricula.turma_id,
      serie_id: matricula.serie_id,
    }));
    const response = await Axios().post("matriculas", form);
    const novaMatricula = response.data;
    return novaMatricula;
  }

  async transferirMatricula(form) {
    const response = await Axios().post("matriculas/transferir-matricula", form);
    return response.data;
  }

  async atualizarMatricula(matricula) {
    const response = await Axios().put(`matriculas/${matricula.id}`, matricula);
    const matriculaAtualizada = response.data;
    return matriculaAtualizada;
  }

  async deletarMatricula(matricula) {
    const response = await Axios().delete(`matriculas/${matricula.id}`);
    const matriculaDeletada = response.data;
    return matriculaDeletada;
  }

  async recuperarMatricula(matricula) {
    return this.atualizarMatricula({ ...matricula, deleted_at: null });
  }

  async ocultar(matricula) {
    const response = await Axios().get(`matriculas/ocultar/${matricula.id}`);
    const matriculaOcultado = response.data;
    return matriculaOcultado;
  }

  async verificarSeAlunoPossuiMatriculaNoMunicipio(aluno_id) {
    const response = await Axios().post(
      "matriculas/verificar-se-aluno-possui-matricula-no-municipio",
      { aluno_id }
    );
    return response.data;
  }

  async historico(aluno_id) {
    const response = await Axios().get(`matriculas/historico/${aluno_id}`);
    return response;
  }

  async getMediasdaMatricula(matricula_id) {
    const response = await Axios().get(
      `matriculas/desempenho-de-notas-do-aluno/matricula/${matricula_id}`
    );
    return response.data;
  }

  async getFaltasdaMatricula(matricula_id) {
    const response = await Axios().get(
      `matriculas/desempenho-de-faltas-do-aluno/matricula/${matricula_id}`
    );
    return response.data;
  }

  async informacoes(matricula_id) {
    const response = await Axios().get(`matricula/informacao/${matricula_id}`);
    return response;
  }

  async downloadImage(matricula_id) {
    const base64 = await Axios()
      .get(`aluno/imagem/${matricula_id}`, {
        responseType: "arraybuffer",
      })
      .then((response) => Buffer.from(response.data, "binary").toString("base64"));
    return `data:image/jpeg;base64, ${base64}`;
  }

  async getDetails(matricula_id) {
    const response = await Axios().get(`matriculas/${matricula_id}`);
    return (response.data = new Matricula(response.data));
  }

  async parcelas(parcelas) {
    return parcelas.map((parcela) => new Parcela(parcela));
  }

  async qtd() {
    const response = await Axios().get("matriculas/qtd");
    return response.data;
  }

  async qtdPorFranquia(franquiaId) {
    const response = await Axios().get(`matriculas/qtdPorFranquia/${franquiaId}`);
    return response.data;
  }

  async taxaEvasao(franquiaId) {
    const response = await Axios().get(`matriculas/taxaEvasao/${franquiaId}`);
    return response.data;
  }
}

export default new MatriculasService();
