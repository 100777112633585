<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="() => $router.push({ name: 'turmas' })"
            :disabled="false"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Turma
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="turma">
        <v-card color="primary lighten-1" class="mb-5" outlined>
          <v-card-title style="color: white"> Turma: {{ turma.descricao }}</v-card-title>
          <v-card-subtitle class="font-weight-medium" style="color: white">
            <div>Professor(a): {{ professor.nome || "- - -" }}</div>
          </v-card-subtitle>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="mt-3">
      <template>
        <v-tabs v-model="tab_atual" background-color="primary" dark>
          <v-tab v-for="tab of tabs" :key="tabs.indexOf(tab)">{{ tab.descricao }}</v-tab>
        </v-tabs>
        <div class="text-right">
          <v-btn color="primary" class="ml-4 mt-4 mr-4 white--text" @click="gerarRelatorio">
            Lista da turma
            <v-icon right dark> mdi-file </v-icon>
          </v-btn>
        </div>
        <v-card-text>
          <v-simple-table v-if="matriculas" class="elevation-1" v-show="tab_atual == 0">
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">N º</th>
                  <th class="text-left">Aluno</th>
                  <th v-if="$can(['matriculas.rfid'])" class="text-left">RFID</th>
                  <th class="text-center">Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="matricula of matriculas"
                  :key="matricula.id"
                  :class="!matricula.aluno ? 'error' : ''"
                >
                  <td width="20">{{ matriculas.indexOf(matricula) + 1 }}</td>
                  <td class="font-weight-medium text-left">
                    <div>
                      {{
                        matricula.aluno
                          ? matricula.aluno.nomecompleto
                          : `[ALUNO POSSIVELMENTE DELETADO] MATRICULA: ${matricula.id}`
                      }}
                    </div>
                    <div>
                      <small>
                        {{ matricula.aluno ? matricula.aluno.codigo_inep : "- - -" }} /
                        {{ matricula.aluno ? matricula.aluno.codigo : "- - -" }}
                      </small>
                    </div>
                  </td>
                  <td v-if="$can(['matriculas.rfid'])">
                    {{ matricula ? matricula.codigo_frid : "" }}
                  </td>
                  <td class="text-center">
                    <v-tooltip bottom v-if="matricula.aluno && $can(['matriculas.rfid'])">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          v-if="
                            !$constants.situacoesMatriculaCondition[
                              removerEspaco(matricula.situacao ? matricula.situacao : '')
                            ]
                          "
                          class="ml-2"
                          color="red lighten-5"
                          v-bind="attrs"
                          v-on="on"
                          @click="() => formRfid(matricula)"
                        >
                          <v-icon color="red lighten-1" small> fa fa-id-card</v-icon>
                        </v-btn>
                      </template>
                      <span>Cadastrar código FRID</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="matricula.aluno">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          class="ml-1"
                          color="blue lighten-3"
                          v-bind="attrs"
                          v-on="on"
                          :to="{
                            name: 'relatorios.lancarRelatorioBNCC',
                            params: { matricula_id: matricula.id },
                          }"
                        >
                          <v-icon small>fa fa-file-invoice</v-icon>
                        </v-btn>
                      </template>
                      <span>Lançar relatório da(e) {{ turma.curso.descricao }}</span>
                    </v-tooltip>
                    <v-tooltip bottom v-if="matricula.aluno">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          x-small
                          class="ml-2"
                          color="red lighten-5"
                          v-bind="attrs"
                          v-on="on"
                          @click="() => RelatorioBNCC(matricula.id)"
                        >
                          <v-icon color="red lighten-1" small> fa fa-file-pdf</v-icon>
                        </v-btn>
                      </template>
                      <span>Gerar relatório da(e) {{ turma.curso.descricao }}</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </template>
    </v-card>
    <v-dialog :value="modal_rfid" persistent max-width="450px">
      <v-card>
        <v-card-title
          ><v-icon size="25" class="mr-2" color="primary"> credit_card </v-icon> Cadastrar RFID
          <v-spacer></v-spacer>
          <template>
            <v-btn @click="modal_rfid = !modal_rfid" icon>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template></v-card-title
        >
        <v-card-text>
          <div class="d-flex align-cente mb-2">
            <span class="ml-4 text-body-2"></span>
          </div>
          <div class="d-flex flex-column">
            <v-form ref="form" @submit.prevent="cadastrar_rfid">
              <v-text-field
                v-model="form_rfid.rfid"
                filled
                prepend-inner-icon="mdi-account-outline"
                :label="`${aluno ? aluno.nomecompleto : ''}`"
                required
              />
            </v-form>
            <v-row class="mb-4">
              <v-container fluid>
                <v-btn @click="cadastrar_rfid" class="success_icon white--text" type="button">
                  Cadastrar código RFID
                </v-btn>
              </v-container>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </main-template>
</template>

<script>
export default {
  data() {
    return {
      turma: null,
      matriculas: null,
      disciplinas: null,
      gestoes_de_aulas: null,
      tab_atual: 0,
      tabs: [
        {
          id: 0,
          descricao: "Lista da Turma",
        },
      ],
      modal_rfid: false,
      form_rfid: {
        rfid: null,
      },
      aluno: null,
    };
  },
  mounted() {
    this.loadBasicData();
  },
  created() {
    this.loadBasicData();
  },
  methods: {
    formRfid(matricula) {
      this.form_rfid.matricula_id = matricula.id;
      this.aluno = matricula.aluno;
      this.modal_rfid = !this.modal_rfid;
    },
    async gerarRelatorio() {
      this.$loaderService.open("Carregando dados...");
      await this.$services.relatoriosService.listarTurma(parseInt(this.$route.params.turmaId, 10));
      this.$loaderService.close();
    },
    async RelatorioBNCC(matricula_id) {
      this.$loaderService.open("Carregando dados...");
      await this.$services.relatoriosService.relatorioBNCC(parseInt(matricula_id, 10));
      this.$loaderService.close();
    },
    async loadBasicData() {
      this.$loaderService.open("Carregando dados...");
      try {
        const payload = await this.$services.gestoesDeAulasService.visualizar(
          parseInt(this.$route.params.gestaoDeAulasId, 10)
        );
        this.turma = payload.turma;
        this.professor = payload.professor;
        this.matriculas = payload.turma.matriculas;
        this.disciplinas = payload.disciplinas;
      } catch (error) {
        this.$handleError(error);
      }

      this.$loaderService.close();
    },
    removerEspaco(string) {
      return string.replace(" ", "_").replace(" ", "_").replace(" ", "_");
    },
    async cadastrar_rfid() {
      try {
        await this.$services.notifiqService.cadastrar_rfid(this.form_rfid);
        this.$toast.success("Código atualizado com sucesso.");
        this.loadBasicData();
        this.modal_rfid = !this.modal_rfid;
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>

<style></style>
