<template>
  <main-template>
    <v-row>
      <v-col>
        <span class="text-h4">Retirada do Caixa</span>
      </v-col>
    </v-row>

    <v-card class="mt-3">
      <v-row>
        <v-col cols="12" sm="6" xs="12" v-if="caixaAtual">
          <v-list-item-content class="px-5 my-3">
            <v-list-item-title class="mt-3 caption">
              <span class="font-weight-bold"> Situação: {{ caixaAtual.descricao }}</span>
              <div></div>
              <span class="font-weight-bold">
                <v-chip v-if="situacoes.situacao === 1" small label color="green lighten-4">
                  ABERTO
                </v-chip>
                <v-chip v-if="situacoes.situacao === 0" small label color="warning lighten-4">
                  FECHADO
                </v-chip>
                <!-- {{ situacoes }} -->
                <v-chip v-if="sangriasDoCaixa.length < 0" small label color="red lighten-4">
                  Sem Movimentação
                </v-chip>
              </span>
            </v-list-item-title>

            <v-list-item-subtitle class="mt-3 caption">
              <span class="font-weight-bold">
                Criado por: {{ caixaAtual.user ? caixaAtual.user.email : "--" }} | Criação:
                {{ caixaAtual.created_at | formatarDataCaixa }}
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-col>

        <v-divider vertical></v-divider>

        <v-col rols="3" class="d-flex flex-column align-center">
          <div>Valor Total do Caixa:</div>
          <div class="display-2 mt-5">
            <span>
              {{ (totalDoCaixa - this.somaTotal(this.sangriasDoCaixa)) | currency }}
            </span>
          </div>
        </v-col>

        <v-divider vertical></v-divider>

        <v-col rols="3" class="d-flex flex-column align-center">
          <div>Valor da sangria:</div>
          <form method="post"></form>
          <div class="display-3">
            <v-currency-field
              :disabled="!user.caixa"
              :background-color="errorValorSangria ? 'deep-orange lighten-4' : ''"
              :class="errorValorSangria ? 'animate__animated animate__flash animate__infinite' : ''"
              align-text="center"
              class="display-1"
              v-model="valorSangria"
            >
            </v-currency-field>
          </div>
          <v-btn
            type="submit"
            @click="() => sangria()"
            :disabled="errorValorSangria || !user.caixa"
            color="blue"
            class="white--text"
          >
            Retirar
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

    <v-row class="mt-5">
      <v-col>
        <v-row>
          <v-col>
            <p>Lista de sangrias do caixa</p>
          </v-col>
        </v-row>

        <v-card>
          <v-simple-table>
            <template>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Data</th>
                  <th>Usuário</th>
                  <th class="text-center">Situação</th>
                  <th class="text-center">Valor</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in sangriasDoCaixa" :key="item.id">
                  <td>{{ item ? item.id : "- - -" }}</td>
                  <td>{{ item.created_at | formatarDataCaixa }}</td>
                  <td>{{ item ? item.user.name : "- - -" }}</td>
                  <td class="text-center">
                    <v-chip
                      :color="
                        parseInt(item.situacao, 10) === 1 ? 'green lighten-4' : 'red lighten-4'
                      "
                    >
                      {{ parseInt(item.situacao, 10) === 1 ? "Aberto" : "Fechado" }}
                    </v-chip>
                  </td>
                  <td class="text-right">{{ item.valor | currency }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="4" class="text-right font-weight-bold">Total</td>
                  <td class="text-right font-weight-bold">
                    {{ somaTotal(sangriasDoCaixa) | currency }}
                  </td>
                </tr>
              </tfoot>
            </template>
          </v-simple-table>
        </v-card>
      </v-col>

      <v-dialog v-model="showDialog" persistent max-width="400">
        <v-card>
          <v-card-title class="text-h5"> Caixa fechado </v-card-title>
          <v-card-text>Por favor abra o caixa antes de continuar.</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="[(showDialog = false), abrirCaixa()]">
              Abrir Caixa
            </v-btn>
            <v-btn color="green darken-1" text @click="voltar()"> Voltar </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("Auth", ["user"]),
    totalDoCaixa() {
      return (
        parseFloat(this.somaTotal(this.mensalidades)) +
        parseFloat(this.somaTotal(this.situacoesCaixa))
      );
    },
  },

  mounted() {
    if (!this.user.caixa) this.showDialog = true;
    this.pesquisar();
    this.getCaixas();
  },

  data() {
    return {
      showDialog: false,
      sangriasDoCaixa: [],
      errorValorSangria: false,
      valorSangria: null,
      situacoesCaixa: [],
      mensalidades: [],
      caixaSelecionado: null,
      caixaAtual: null,
      caixas: [],
      situacoes: null,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
    };
  },

  watch: {
    valorSangria() {
      if (this.valorSangria > this.totalDoCaixa - this.somaTotal(this.sangriasDoCaixa)) {
        this.errorValorSangria = true;
        return;
      }
      this.errorValorSangria = false;
    },
  },

  methods: {
    async pesquisar() {
      this.$loaderService.open("Carregando Movimentação...");
      this.getSangrias(this.user.caixa.id, this.date);
      this.getMensalidades(this.user.caixa.id, this.date);
      this.$loaderService.close();
    },

    async getCaixas(user_id, caixa_id) {
      const response = await this.$services.movimentacaoService.getCaixas(
        user_id,
        this.user.caixa.id,
        this.date
      );
      this.caixaAtual = { ...response.caixa };
      this.situacoes = { ...response.situacao_caixas };
    },

    async getMensalidades(caixa_id, date) {
      const response = await this.$services.movimentacaoService.getMensalidades(caixa_id, date);
      this.mensalidades = [...response.mensalidades];
      this.situacoesCaixa = response.situacoes;
    },

    async getSangrias(caixa_id, date) {
      this.$loaderService.open("Carregando Movimentação...");
      const response = await this.$services.sangriaService.getSangriasDataAtual(caixa_id, date);
      this.sangriasDoCaixa = response;
      this.$loaderService.close();
    },

    async sangria() {
      if (this.valorSangria <= 0) {
        this.$toast.warn("Valor inválido!");
        return;
      }
      const form = {
        user_id: this.user.id,
        caixa_id: this.user.caixa.id,
        valor: this.valorSangria,
        situacao: 1,
      };
      this.$loaderService.open("Realizando sangria");
      await this.$services.sangriaService.salvarSangria(form);
      this.$loaderService.close();
      this.getSangrias(this.user.caixa.id, this.date);
      this.valorSangria = 0;
    },

    async verificarCaixaAberto() {
      if (!this.user.caixa) {
        this.showDialog = true;
      }
    },

    somaTotal(valores) {
      return valores.reduce((ac, vc) => {
        return ac + parseFloat(vc.valor);
      }, 0);
    },

    abrirCaixa() {
      this.$router.push({ name: "caixas" });
    },

    voltar() {
      this.$router.go(-1);
    },
  },
};
</script>
