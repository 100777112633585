<template>
  <div class="wrapper">
    <!-- <img src="/img/INFORMATIVO_epublic.png" width="100%" /> -->
    <v-card elevation="2" class="login-card">
      <v-card-title class="d-flex justify-center">
        <img src="/img/logo.png" width="100" />
      </v-card-title>
      <v-card-text>
        <ValidationObserver v-slot="{ handleSubmit }">
          <v-form ref="form" @submit.prevent="handleSubmit(logar)">
            <ValidationProvider name="Login de acesso" rules="required" v-slot="{ errors }">
              <v-text-field
                v-model="credentials.email"
                filled
                prepend-inner-icon="mdi-account-outline"
                label="Login de acesso"
                required
                :error-messages="errors"
              />
            </ValidationProvider>
            <ValidationProvider name="Senha" rules="required" v-slot="{ errors }">
              <v-text-field
                v-model="credentials.password"
                filled
                prepend-inner-icon="mdi-lock-outline"
                label="Senha"
                :type="showPassword ? 'text' : 'password'"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                required
                :error-messages="errors"
                @click:append="showPassword = !showPassword"
              />
            </ValidationProvider>
            <v-btn
              block
              color="primary"
              type="submit"
              :class="loginButtonClass"
              :disabled="logando"
              :loading="logando"
            >
              Entrar no sistema
            </v-btn>
          </v-form>
        </ValidationObserver>
        <a
          href="https://api.whatsapp.com/send/?phone=86999880525&text=Olá%2C+venho+direto+do+sistema+pedir+uma+ajuda%21&type=phone_number&app_absent=0"
          target="__blank"
          class="help-section d-flex flex-column align-center"
          v-bind:class="{ 'animate__animated animate__headShake': animarAjuda }"
        >
          <v-icon>fa fa-question-circle</v-icon>
          <span>Precisa de ajuda? Clique aqui.</span>
        </a>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";

export default {
  name: "LoginPage",
  data() {
    return {
      credentials: { email: "", password: "" },
      showPassword: false,
      logando: false,
      loginButtonClass: "",
      animarAjuda: false,
    };
  },
  mounted() {
    // this.$Tawk.$setOnLoadFunction(() => this.$Tawk.$hideWidget());
    // this.$Tawk.$hideWidget();
    const switchAnimarAjuda = () => {
      this.animarAjuda = !this.animarAjuda;
      setTimeout(() => switchAnimarAjuda(), 2000);
    };
    switchAnimarAjuda();
  },
  computed: {
    ...mapMutations("RouterCache", [
      "setOriginRoute",
      "setOriginRouteNameVeirification",
      "clearRoutes",
    ]),
    ...mapGetters("RouterCache", ["getRoutes"]),
  },
  methods: {
    async logar() {
      this.logando = true;
      try {
        await this.$services.authService.logar(this.credentials);
        this.$toast.success("Logado no sistema com sucesso!");
        // Alerta: o código abaixo não é para crianças!
        const user_atual = await this.$services.authService.getCurrentUser();
        const rota_a_ser_acessada_name = this.getRoutes.originRouteNameVeirification;
        const originRoute = this.getRoutes.originRoute || { name: "home" };
        let tem_permissao = false;

        user_atual.permissions.forEach(async (e) => {
          if (
            rota_a_ser_acessada_name == e ||
            `${rota_a_ser_acessada_name}.*` == e ||
            `${rota_a_ser_acessada_name.split(".")[0]}.atualizar` == e
          ) {
            tem_permissao = true;
          }
        });

        if (!tem_permissao) {
          this.$router.push({ name: "home" });
          this.clearRoutes();
          return;
        }

        this.$router.push(originRoute);
        this.clearRoutes();
        // localStorage.removeItem("originRoute");
        // localStorage.removeItem("originRouteNameVeirification");
      } catch (error) {
        this.$toast.warn(error.error.message);
        this.loginButtonClass = "animate__animated animate__headShake";
        setTimeout(() => (this.loginButtonClass = ""), 500);
      }
      this.logando = false;
    },
  },
};
</script>

<style scoped>
.wrapper {
  height: 100vh;
  width: 100vw;
  position: absolute;
  background-image: url("../../assets/background_tratato_para_web.jpg");
  background-size: cover;
}
.wrapper > .login-card {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80vw;
  max-width: 600px;
  background-color: rgba(255, 255, 255, 0.7);
}

.help-section > i {
  margin-top: 12px;
  margin-bottom: 4px;
  margin-left: 50%;
  margin-right: 50%;
  transform: translateX(-50%);
}

.help-section {
  text-decoration: none;
  color: inherit;
  font-weight: 500;
}
</style>
