<template>
  <v-list v-if="matricula.aluno">
    <v-list-item>
      <v-list-item-avatar>
        <v-avatar color="primary">
          {{ matricula.aluno.nomecompleto.charAt(0) }}
        </v-avatar>
      </v-list-item-avatar>

      <v-list-item-content three-line>
        <v-list-item-title class="text-uppercase">{{
          matricula.aluno.nomecompleto
        }}</v-list-item-title>
        <v-list-item-subtitle>{{ matricula.turma.descricao }}</v-list-item-subtitle>
        <v-list-item-subtitle
          >Responsável:<span class="font-weight-medium text-uppercase">
            {{ matricula.aluno.responsavel.descricao }}</span
          >
          | CPF:
          <span class="font-weight-medium">{{
            matricula.aluno.responsavel.cpf | formatarCPF
          }}</span></v-list-item-subtitle
        >
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
export default {
  props: {
    matricula: {
      type: Object,
      required: true,
    },
  },
  filters: {
    formatarCPF(cpf) {
      if (cpf && cpf.length === 11) {
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
      }
      return cpf;
    },
  },
};
</script>
