<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Selecione uma Disciplina Plano de Curso</v-card-title
        >

        <v-select
          class="pa-3"
          :items="disciplinas"
          item-text="descricao"
          item-value="id"
          v-model="disciplina_id"
          return-object
          label="Selecione uma disciplina"
          solo
          @change="gerarPlanoCurso(gestaoDeAulasId)"
        ></v-select>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    gestaoDeAulasId: {
      type: Number,
    },
    disciplinas: {
      type: Array,
      required: true,
    },
    dialog: {
      type: Boolean,
      required: true,
    },
  },

  watch: {
    dialog(val) {
      this.$emit("status", val);
    },
  },
  data() {
    return {
      disciplinasArray: this.disciplinas,
      disciplina_id: null,
      disciplinasDaGestao: [],
    };
  },
  mounted() {},
  methods: {
    async gerarPlanoCurso(gestaoDeAulasId) {
      try {
        this.$loaderService.open("Gerando plano de aula...");
        const disciplinaId = this.disciplina_id.id;
        await this.$services.relatoriosService.planoCurso(
          parseInt(gestaoDeAulasId, 10),
          parseInt(disciplinaId, 10)
        );
        this.$loaderService.close();
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>
