import Axios from "@/plugins/Axios";
import Parcela from "@/Models/Parcela";

export class ParcelasService {
  async baixarParcela(parcela, { mensalidades, juros, multa, desconto }) {
    const response = await Axios().post(`parcelas/${parcela.id}/baixar`, {
      mensalidades,
      juros,
      multa,
      desconto,
    });
    // console.log(response);

    const parcelaAtualizada = new Parcela(response.data);
    Object.assign(parcela, parcelaAtualizada);
    return parcelaAtualizada;
  }

  async getParcela(parcelaId) {
    const response = await Axios().get(`parcelas/${parcelaId}/parcela`);
    return (response.data = new Parcela(response.data));
  }

  async getHistorico(parcelaId) {
    const response = await Axios().get(`parcelas/${parcelaId}/historico`);
    return response;
  }

  async getComprovante(parcelaId) {
    const response = await Axios().get(`parcelas/${parcelaId}/comprovante`);
    return response;
  }

  async incluirBoleto(parcela) {
    const response = await Axios().post("parcelas/incluir-boleto", parcela);
    return response;
  }

  async consultarBoleto(parcela) {
    try {
      const response = await Axios()
        .get(`parcelas/consultar-boleto/${parcela.id}`, { responseType: "arraybuffer" })
        .then((response) => {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        });
      return response;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async novaParcela(formulario) {
    const response = await Axios().post(`parcelas/nova-parcela`, formulario);
    return response;
  }

  async getCaixaUser(user_id) {
    const response = await Axios().get(`parcelas/${user_id}/tem-caixa`);
    return response;
  }

  async estorno(estorno) {
    const response = await Axios().post(`parcelas/estorno`, estorno);
    return response;
  }

  async deletarParcela(parcelaId) {
    try {
      const response = await Axios().delete(`parcelas/deletar/${parcelaId}`);
      return response;
    } catch (error) {
      throw new Error(error.message);
    }
  }
}
export default new ParcelasService();
