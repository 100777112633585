<template>
  <v-dialog ref="dialog" v-model="modal" :return-value.sync="data" persistent width="290px">
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="data"
        persistent-hint
        label="Escolha uma data"
        v-mask="'##/##/####'"
        :error-messages="errorMessages"
        readonly
        v-bind="attrs"
        v-on="on"
        dense
        solo
      ></v-text-field>
    </template>
    <v-date-picker v-model="calendario" scrollable>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="modal = false"> Cancelar </v-btn>
      <v-btn text color="primary" @click="saveDate"> OK </v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
import { dateFormat } from "../plugins/filters";

export default {
  props: {
    value: {
      required: true,
    },
    errorMessages: {
      required: false,
    },
  },
  data() {
    return {
      data: this.value ? dateFormat(this.value) : "",
      modal: false,
      calendario: this.value || null,
    };
  },
  model: {
    prop: "value",
    event: "change",
  },
  watch: {
    calendario(value) {
      this.data = dateFormat(value);
      this.$emit("change", value);
    },
    value(value) {
      this.data = dateFormat(value);
      this.calendario = value;
    },
  },
  methods: {
    saveDate() {
      this.modal = false;
      this.$refs.dialog.save(this.calendario);
    },
  },
};
</script>
