import Axios from "@/plugins/Axios";

export class NotasService {
  async get(gestaoDeAulaId) {
    const response = await Axios().get(`notas/${gestaoDeAulaId}`);
    const payload = response.data;
    const notas = Array.isArray(payload.notas) ? {} : payload.notas;
    payload.matriculas.map((matricula) => {
      if (!notas[matricula.id]) {
        notas[matricula.id] = {
          matricula_id: matricula.id,
          turma_id: payload.turma.id,
          disciplina_id: payload.disciplina.id,
        };
      }
      return matricula;
    });
    return { ...payload, notas };
  }

  async salvarNotas(gestaoDeAulaId, notas) {
    await Axios().patch(`notas/${gestaoDeAulaId}`, notas);
  }

  async salvarNotasCircuito(gestaoDeAulaId, notas) {
    await Axios().patch(`nota-circuito/${gestaoDeAulaId}`, notas);
  }

  async getCircuitoEtapas(gestaoDeAulaId) {
    const response = await Axios().get(`circuito-notas/get-etapas/${gestaoDeAulaId}`);
    const payload = response.data;
    return { ...payload };
  }

  async getNotasCircuito(gestaoDeAulaId, disciplinaId) {
    const response = await Axios().get(`nota-circuito/${gestaoDeAulaId}/${disciplinaId}`);
    const payload = response.data;
    const notasCircuito = Array.isArray(payload.notasCircuito) ? {} : payload.notasCircuito;
    payload.matriculas.map((matricula) => {
      if (!notasCircuito[matricula.id]) {
        notasCircuito[matricula.id] = {
          valores: [],
          valores_texto: [],
          matricula_id: matricula.id,
          turma_id: payload.turma.id,
          disciplina_id: payload.disciplina.id,
        };
      }
      return matricula;
    });

    const notasGlobais = Array.isArray(payload.notasGlobais) ? {} : payload.notasGlobais;
    payload.matriculas.map((matricula) => {
      if (!notasGlobais[matricula.id]) {
        notasGlobais[matricula.id] = {
          valores: [],
          valores_texto: [],
          matricula_id: matricula.id,
          turma_id: payload.turma.id,
        };
      }
      return matricula;
    });

    return { ...payload, notasCircuito, notasGlobais };
  }
}
export default new NotasService();
