import Axios from "@/plugins/Axios";

export class SistemaBNCCService {
  async syncAll(matricula) {
    const response = await Axios().post("sistema-bncc", { id: matricula });
    const payload = response.data;
    const { resumos } = payload;
    const notas = Array.isArray(payload.notas) ? {} : payload.notas;
    payload.sistema.sistema_b_n_c_c.children.map((parent) =>
      parent.children.map((sistemaBNCC) => {
        if (!notas[sistemaBNCC.id]) {
          notas[sistemaBNCC.id] = {
            matricula_id: payload.matricula.id,
            sistema_bncc_id: sistemaBNCC.id,
          };
        }
        return sistemaBNCC;
      })
    );
    return { ...payload, notas, resumos };
  }

  async listarTodos() {
    const response = await Axios().get("sistema-bncc");
    return response.data;
  }

  async createOrUpdate(bncc, matricula_id, semestre) {
    const response = await Axios().post("sistema-bncc/criar-bncc", {
      bncc,
      matricula_id,
      semestre,
    });
    return response.data;
  }

  async getGestaoRelatorioBncc(matricula_id) {
    const response = await Axios().get(`sistema-bncc/gestao-relatorio-bncc/${matricula_id}`);
    return response.data;
  }
}
export default new SistemaBNCCService();
