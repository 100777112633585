import MapCollection from "collections/map";
import cursosService from "@/Services/CursosService";
import $handleError from "@/plugins/handleError";

const state = () => ({
  cursos: [],
  carregandoCursos: false,
  cursosLoaded: false,
  cursosList: [],
});
const mutations = {
  setCursos(state, cursos) {
    const cursosMap = new MapCollection();
    cursos.map((curso) => cursosMap.set(curso.id, curso));
    state.cursos = cursosMap;
    state.cursosLoaded = true;
    // state = {...state, cursos: cursosMap, cursosLoaded: true}
  },
  setCarregandoCursos(state, newValue) {
    state.carregandoCursos = newValue;
  },
  adicionarCurso(state, curso) {
    state.cursos.set(curso.id, curso);
  },
  atualizarCurso(state, curso) {
    state.cursos.set(curso.id, curso);
  },
  deletarCurso(state, cursoId) {
    state.cursos.delete(cursoId);
  },
};
const actions = {
  async loadCursos({ commit }, options = {}) {
    commit("setCarregandoCursos", true);
    try {
      const cursosList = await cursosService.syncAll();
      commit("setCursos", cursosList);
    } catch (error) {
      $handleError(error);
    }
    commit("setCarregandoCursos", false);
  },
};
const getters = {
  cursos: (state) => state.cursos,
  carregandoCursos: (state) => state.carregandoCursos,
  cursosLoaded: (state) => state.cursosLoaded,
  cursosList: (state) => Array.from(state.cursos.values()),
};
const namespaced = true;
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced,
};
