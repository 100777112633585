import Axios from "@/plugins/Axios";

export class PlanosBimestraisService {
  async planoBimestral(gestaoDeAulaId, bimestre, disciplinaId) {
    const response = await Axios().get(
      `planos-bimestrais/plano-bimestral/${gestaoDeAulaId}/${bimestre}/${disciplinaId}`
    );
    return response.data;
  }

  async planoMensal(gestaoDeAulaId, mes) {
    const response = await Axios().get(`planos/plano-mensal/${gestaoDeAulaId}/${mes}`);
    return response.data;
  }

  async planoMensalInfantil(gestaoDeAulaId, mes) {
    const response = await Axios().get(`planos/plano-mensal-infantil/${gestaoDeAulaId}/${mes}`);
    return response.data;
  }

  async createOrUpdate(plano) {
    const response = await Axios().post("planos/criar-plano-bimestral", {
      plano,
    });
    return response.data;
  }

  async planoMensalCreateOrUpdate(plano) {
    const response = await Axios().post("planos/plano-mensal/create-or-update", {
      plano,
    });
    return response.data;
  }

  async planoMensalInfantilCreateOrUpdate(plano) {
    const response = await Axios().post("planos/plano-mensal-infantil/create-or-update", {
      plano,
    });
    return response.data;
  }

  async planoBimestralInfanil(gestaoDeAulaId, bimestre) {
    const response = await Axios().get(
      `planos/plano-bimestral-infantil/${gestaoDeAulaId}/${bimestre}`
    );
    return response.data;
  }

  async createOrUpdateInfantil(plano) {
    const response = await Axios().post("planos/criar-plano-bimestral-infantil", {
      plano,
    });
    return response.data;
  }

  async createOrUpdatePlanoDeCurso(plano) {
    const response = await Axios().post("planos/criar-plano-de-curso", {
      plano,
    });
    return response.data;
  }

  async getPlanoCurso(gestaoDeAulaId, disciplinaId) {
    const response = await Axios().get(`planos/${gestaoDeAulaId}/${disciplinaId}`);
    const payload = response.data;

    return { ...payload };
  }
}
export default new PlanosBimestraisService();
